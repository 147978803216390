/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner0 from './Banner0';
import Feature0 from './Feature0';
import Feature5 from './Feature5';
import Footer2 from './Footer2';

import {
  Banner00DataSource,
  Feature02DataSource,
  Feature50DataSource,
  Feature01DataSource,
  Footer20DataSource,
  Banner00DataSourceMobile
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;
let children =[];
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    if(this.state.isMobile){
      children = [
        <Banner0
          id="Banner0_0"
          key="Banner0_0"
          dataSource={Banner00DataSourceMobile}
          isMobile={this.state.isMobile}
        />,
        // <Feature0
        //   id="Feature0_1"
        //   key="Feature0_1"
        //   dataSource={Feature01DataSource}
        //   isMobile={this.state.isMobile}
        // />,
             
        <Feature5
          id="Feature5_0"
          key="Feature5_0"
          dataSource={Feature50DataSource}
          isMobile={this.state.isMobile}
        />,
   
        // <Feature0
        //   id="Feature0_2"
        //   key="Feature0_2"
        //   dataSource={Feature02DataSource}
        //   isMobile={this.state.isMobile}
        // />,
        <Footer2
          id="Footer2_0"
          key="Footer2_0"
          dataSource={Footer20DataSource}
          isMobile={this.state.isMobile}
        />,
      ];
    }
    else {
    children = [
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Feature0
      //   id="Feature0_1"
      //   key="Feature0_1"
      //   dataSource={Feature01DataSource}
      //   isMobile={this.state.isMobile}
      // />,
           
      <Feature5
        id="Feature5_0"
        key="Feature5_0"
        dataSource={Feature50DataSource}
        isMobile={this.state.isMobile}
      />,
 
      // <Feature0
      //   id="Feature0_2"
      //   key="Feature0_2"
      //   dataSource={Feature02DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Footer2
        id="Footer2_0"
        key="Footer2_0"
        dataSource={Footer20DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
  }

    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
