import React from 'react';
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: './assets/cd_logo_prd_final_text.svg',
  },
  content: {
    className: 'banner0-content',
    children: '2019.10.23',
  },
  content2: {
    className: 'banner0-content2',
    children: '地域包括ケア EXPO [東京] 幕張メッセ',
  },

};
export const Banner00DataSourceMobile = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: './assets/cogd-logo-md-small.png',
  },
  content: {
    className: 'banner0-content',
    children: '2019.10.23',
  },
  content2: {
    className: 'banner0-content2',
    children: '地域包括ケア EXPO [東京] 幕張メッセ',
  },

};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'IOT健康管理システム',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '〇〇〇〇〇〇〇〇〇〇〇〇〇〇',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'HEALTH,SAFETY,ENVIRONMENT',
        className: 'title-h1',
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',

        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>

              </>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              './assets/description.png',
              children2:
              './assets/description2.png',
            md: 10,
            xs: 24,
          },
        },
        // },{
        //   name: 'block1',
        //   tag: {
        //     className: 'content7-tag',
        //     text: { children: '詳細', className: 'content7-tag-name' },
        //     icon: { children: 'home' },
        //   },
        //   content: {
        //     className: 'content7-content2',
        //     text: {
        //       className: 'content7-text2',
        //       md: 14,
        //       xs: 24,
        //       children: (
        //         <>
        //           <span>
        //             <h1 style={{color : "gray"}}>近日公開</h1>
        //             <br />

        //           </span>
        //         </>
        //       ),
        //     },
        //     img: {
        //       className: 'content7-img',
        //       children:
        //         '',
        //       md: 10,
        //       xs: 24,
        //     },
        //   },
      }

    ],
  },
};
export const Feature02DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'システム' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              './assets/outline_developer_board_black_48dp.png',
          },
          title: { className: 'content0-title', children: 'センサーフュージョン' },
          content: { children: '' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            './assets/outline_device_hub_black_48dp.png',
          },
          title: {
            className: 'content0-title',
            children: (
              <>
                <span>
                  <p>
                  エッジ               
                  </p>
                  <p>
                  コンピューティング               
                  </p>
                  <br />
                </span>
              </>
            ),
          },
          content: { children: '' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            './assets/outline_cloud_black_48dp.png',
          },
          title: { className: 'content0-title',
          children: (
            <>
              <span>
                <p>
                クラウド                
                </p>
                <p>
                コンピューティング                
                </p>
                <br />
              </span>
            </>
          ), },
          content: { children: '' },
        },
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'サービス' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            './assets/outline_poll_black_48dp.png',
          },
          title: { className: 'content0-title', children: '利用者様運動量の記録' },
          content: {
            children: (
              <>
                <span style={{fontSize:'12px'}}>
                  <p>
                  運動量のリアルタイム表示
                  </p>
                  <p>
                  日次、週次、月次のサマリー
                  </p>
                  <p>
                  AI分析による利用者様体調変化の予測
                  </p>
                </span>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            './assets/outline_place_black_48dp.png',
          },
          title: {
            className: 'content0-title',
            children: '利用者様の位置特定',
          },
          content: {
            children: (
              <>
                <span style={{fontSize:'12px'}}>
                  <p>
                  施設フロア図に利用者様の居場所をリアルタイム表示
                  </p>
                  <p>
                  転倒検知・離れ検知・徘徊検知等のリアルタイム通知
                  </p>
                </span>
              </>
            ),
          },        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            './assets/outline_wb_sunny_black_48dp.png',
          },
          title: { className: 'content0-title', children: '環境モニタリング' },
          content: {
            children: (
              <>
                <span style={{fontSize:'12px'}}>
                  <p>
                  温度・湿度・照度・空気品質等のモニタリング
                  </p>
                  <p>
                  活動と環境の相関をAI分析でレポーティング
                  </p>
                </span>
              </>
            ),
          }, 
        },
      },
    ],
  },
};


export const Footer20DataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [

      {
        name: 'copyright',
        children: '　Copyright © 2019 FirstLane Technology Inc.',
        className: 'copyright-text',
      },

    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'logo',
        href: 'http://fl-tech.jp/',
        className: 'links-logo',
        children:
          './assets/logo.svg',
      }

    ],
  },
};
